<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn small depressed color="fmq_gray" dark outlined to="/">
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
        </div>
        <SolicitacaoStepper
          @send="criar"
          :listMedicoSolicitante="listMedicoSolicitante"
          :listProdutos="listProdutos"
          :listAssuntos="listAssuntos"
          :listRepresentantes="listRepresentantes"
          :loadingBtn="loadingBtn"
          :representanteId="representanteId"
        />
      </div>
    </div>
    <AlertSuccess
      :dialog="success"
      dialogMessage="Solicitação criada com sucesso"
      @close="goToEspecialidade"
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import { criarSolicitacao } from "@/services/solicitacoes";
import SolicitacaoStepper from "@/components/solicitacoes/SolicitacaoStepper.vue";
import { listarMedicoSolicitante } from "@/services/medico-solicitante";
import { listarProdutos } from "@/services/produtos";
import { listarRepresentantes } from "@/services/user";
export default {
  name: "SolicitacoesCriar",
  components: { SolicitacaoStepper },
  data: () => ({
    breadcrumbs: [
      {
        text: "Lista de Solicitações",
        disabled: false,
        to: "/",
      },
      {
        text: "Criar Solicitação",
        disabled: true,
        to: "",
      },
    ],
    error: false,
    success: false,
    loadingBtn: false,
    messageError: null,
    listMedicoSolicitante: [],
    listProdutos: [],
    listAssuntos: [
      { name: "Comparativo", value: "Comparativo" },
      { name: "Comprovação de segurança", value: "Comprovação de segurança" },
      {
        name: "Comprovação de eficácia e segurança",
        value: "Comprovação de eficácia e segurança",
      },
      { name: "Mecanismo de ação", value: "Mecanismo de ação" },
      {
        name: "Material de marketing (Separata ou Monografia)",
        value: "Material de marketing (Separata ou Monografia)",
      },
      { name: "Modo de uso/posologia", value: "Modo de uso/posologia" },
      { name: "Composição do produto", value: "Composição do produto" },
      { name: "Referência bibliográfica", value: "Referência bibliográfica" },
      { name: "Padronização", value: "Padronização" },
      { name: "Outros", value: "Outros" },
    ],
    listRepresentantes: [],
    representanteId: null
  }),
  created() {
    this.getMedicosSolicitantes();
    this.getProdutos();
    this.getRepresentantes();
  },
  methods: {
    async criar(event) {
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await criarSolicitacao(event).then(() => {
          this.success = true;
        });
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    goToEspecialidade() {
      this.sucess = false;
      this.$router.push({ name: "Solicitacoes" });
    },
    async getMedicosSolicitantes() {
      const resp = await listarMedicoSolicitante();
      this.listMedicoSolicitante = resp.data;
    },
    async getProdutos() {
      const resp = await listarProdutos();
      this.listProdutos = resp.data;
    },
    async getRepresentantes() {
      const resp = await listarRepresentantes();
      this.listRepresentantes = resp.data;
      if(this.$store.state.user.data.tipo === "Representante") {
        const representante = this.listRepresentantes.find(obj => obj.id === this.$store.state.user.data.id);
        this.representanteId = representante.id
      }
    },
  },
};
</script>

<style></style>
